#root {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body, html {
  height: 100vh;
  color: #00d107;
  background-color: black;
  font-family: "Courier"
}

.notice, .selectedthread {
  color: black;
  background-color: #00d107;
  vertical-align: middle;
}

.notice {
  height: 5vh;
  padding: -10px;
}

.navborder {
  border-top-style: dotted;
  border-top-color: #00d107;
  border-left-style: dotted;
  border-left-color: #00d107;
  border-right-style: dotted;
  border-right-color: #00d107;
}

.content {
  height: 95vh;
}

.submitbutton, .loginform, .warning {
  border: 2px solid #00d107;
  color: #00d107;
}